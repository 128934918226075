
import { BsBank2, BsCashCoin, BsDiagram3, BsFileEarmarkArrowUp, BsInstagram, BsLinkedin, BsMegaphone, BsPeople, BsShieldLock, BsSignpost2 } from 'react-icons/bs';
import { VscOrganization } from 'react-icons/vsc';
import { RiUserStarLine } from 'react-icons/ri';
import { BiBarChartAlt2, BiBuildings, BiDownload, BiLandscape, BiLineChart, BiMapPin, BiNews, BiStreetView, BiTrophy, } from 'react-icons/bi';
import { IoAnalyticsOutline } from 'react-icons/io5';
import { HiOutlinePresentationChartBar } from 'react-icons/hi'

import styled from 'styled-components';

import {
    ProteccionFamiliarIcon,
    RespaldoEconomicoIcon,
    FuturoConfiableIcon,
    Permanencia3aniosIcon,
    Permanencia90DiasIcon,
    Permanencia180DiasIcon,
    Permanencia370DiasIcon,
    ContrastLogo,
    ProvidaLogo,
    AhorroMensualIcon,
    Logo,
    LogoSquare,
    RentaplusIcon,
    SuperiorIcon,
    EstrategicoIcon,
    MasterIcon,
    FamilyIcon,
    AhorroIcon,
    CalendarioIcon,
    CamillaAsitenciaIcon,
    EscritorioIcon,
    LapidaIcon,
    EscudoProtegidoIcon,
    TiempoDineroIcon,
    WavePrimaryIcon,
    ProteccionIcon,
    RespaldoIcon,
    WaveTertiaryIcon,
    WaveSecondaryIcon,
    SillaRuedasIcon,
    MoneyBagIcon,
    Circle370Icon
} from './svgs';
import { BiLogOut, BiCog } from 'react-icons/bi'
import { CgStories } from 'react-icons/cg';
import { FaFacebook } from 'react-icons/fa';

export const iconKeys = {
    "quienes_somos": <BsBank2 />,
    "gente_genesis": <VscOrganization />,
    "comunicados_interes": <BsMegaphone />,
    "inversion": <BiLineChart />,
    "largoPlazo": <BiLineChart />,
    "empleadores": <BiBuildings />,
    "personas": <BiStreetView />,
    "contactanos": <BiMapPin />,
    "nuestras_inversiones": <BiLineChart />,
    "noticias": <BiNews />,
    "hechos_relevantes": <BiTrophy />,
    "normativas": <BsSignpost2 />,
    "investors": <VscOrganization />,
    "portal_financiero": <BiBarChartAlt2 />,
    "descargas": <BiDownload />,
    "money_give": <IoAnalyticsOutline />,
    "money_back": <BsCashCoin />,
    "investment": <BsDiagram3 />,
    "unit_value": <RiUserStarLine />,
    "unit_value_grow": <HiOutlinePresentationChartBar />,
    "respaldo_economico": <RespaldoEconomicoIcon />,
    "proteccion_familiar": <ProteccionFamiliarIcon />,
    "ahorro_mensual": <AhorroMensualIcon />,
    "futuro_confiable": <FuturoConfiableIcon />,
    "permanencia_3_anios": <Permanencia3aniosIcon />,
    "permanencia_90_dias": <Permanencia90DiasIcon />,
    "permanencia_180_dias": <Permanencia180DiasIcon />,
    "permanencia_370_dias": <Permanencia370DiasIcon />,
    "contrast_logo": <ContrastLogo />,
    "logo": <Logo />,
    "logo_square": <LogoSquare />,
    "provida_logo": <ProvidaLogo />,
    "contenidos": <CgStories />,
    "galeria": <BiLandscape />,
    "usuarios": <BiCog />,
    "logout": <BiLogOut />,
    "clientepotencial": <BsPeople />,
    "rentaplus": <RentaplusIcon />,
    "ahorro": <AhorroIcon />,
    "superior": <SuperiorIcon />,
    "estrategico": <EstrategicoIcon />,
    "master": <MasterIcon />,
    "horizonte": <FamilyIcon />,
    "seguridad_y_datos": <BsShieldLock />,
    "family_icon": <FamilyIcon />,
    "calendario": <CalendarioIcon />,
    "camilla_asistencia": <CamillaAsitenciaIcon />,
    "escritorio": <EscritorioIcon />,
    "escudo_protegido": <EscudoProtegidoIcon />,
    "lapida": <LapidaIcon />,
    "proteccion": <ProteccionIcon />,
    "respaldo": <RespaldoIcon />,
    "silla_ruedas": <SillaRuedasIcon />,
    "tiempo_dinero": <TiempoDineroIcon />,
    "primary": <WavePrimaryIcon />,
    "secondary": <WaveSecondaryIcon />,
    "terciary": <WaveTertiaryIcon />,
    "moneyBag": <MoneyBagIcon />,
    "circle370": <Circle370Icon />,
    "facebook": <FaFacebook />,
    "instagram": <BsInstagram />,
    "linkedin": <BsLinkedin />,
    "documentos": <BsFileEarmarkArrowUp />
};

const IconSwitcher = ({ name, size, style }) => {
    if (style) {
        const { fontSize, height } = style;
        return (
            <IconWrap2 fontSize={fontSize} height={height}>
                {iconKeys[name]}
            </IconWrap2>
        )
    }
    return (
        <IconWrap size={size} >
            {iconKeys[name]}
        </IconWrap>
    )
}




export default IconSwitcher;


const IconWrap = styled.div`
    svg{
        height: ${props => props.size ? props.size : 3}em;
        width:100%;
    }
`;


const IconWrap2 = styled.div`
    svg{
        height:${props => props.height ? props.height : 0};
        font-size:${props => props.fontSize ? props.fontSize : 0};
    }
`;
