import BoxUi from '@components/common/BoxUi';
import { CSS_HELPERS, MEDIA_SCREENS, THEME_COLORS } from '@theme/constants'
import { Row, Col } from 'antd'
import { BiSupport } from 'react-icons/bi'
import styled from 'styled-components'

const Footer = () => {
    return (
        <>
            <View className='footer'>
                <BoxUi noppadingX transparent>
                    <Row>
                        <Col xs={0} sm={24} >
                            <div className='phone_side_main'>
                                <BiSupport />
                                <p className='md pr1'>Línea de Atención al Cliente  <span className="strong900">7 200 200</span></p>
                            </div>
                        </Col>
                        <Col xs={0} sm={24} >
                            <div className='phone_side_main'>
                                <p className='md pr2'>GENESIS, Administradora de Fondos. &copy;Todos los derechos reservados.</p>
                            </div>
                        </Col>


                        <Col xs={24} sm={0} >
                            <div className='phone_side_main'>
                                <BiSupport />
                                <p className='md pr1'>Callcenter  <span className="strong900">7 200 200</span></p>
                            </div>
                        </Col>


                        <Col xs={24} sm={0} >
                            <div className='phone_side_main'>
                                <p className='md pr2'>GENESIS &copy;Todos los derechos reservados.</p>
                            </div>
                        </Col>

                    </Row>
                </BoxUi>
            </View>
        </>

    )
}

export default Footer;

const View = styled.div`
    bottom:0;
    width:100%;
    position:sticky;
    border-top: 1pt solid ${THEME_COLORS.light.primary};
    
    background:${THEME_COLORS.light.primary.alt + 'EF'};
    color:${THEME_COLORS.light.primary.alt};
    border-top:3pt solid ${THEME_COLORS.light.secondary.main};
    z-index: 2; 
    color:white;
    p{
       ${CSS_HELPERS.TRUNCATE};
       font-size: 1.1em;
    }
    .phone_side_main {
        /* height:2em; */
        cursor:pointer;
        display:flex;
        gap: 0.3em;
        color: 12px ;
        align-items:center;
        justify-content:center;
        text-align:center;
        svg{
            font-size:18px;
            height:18px;
            margin-top: 10px;
        }

    }

    p.pr1{
        padding: 10px 0 0;
    }
    p.pr2{
        padding: 0 0 10px;
    }


`;


const MenuIcon = styled.div`
    bottom:6em;
    z-index:3;
    display:flex;
    justify-content:center;
    height:1.5em;
    cursor:pointer;
    .icon_container{
        position:absolute;
        border-radius:50%;
        bottom:6em;
        width:3em;
        height:3em;
        background:white;
        display:flex;
        justify-content:center;
        align-items:center;
        border:3pt solid ${THEME_COLORS.light.bg.alt};
        ${CSS_HELPERS.TSCALE};
        svg{
            font-size:2.1em;
            color:${THEME_COLORS.light.primary.alt};
        }
        @media screen and (min-width: ${MEDIA_SCREENS.SM}px) { 
            display:none;
        }
    }
   
    
`

