import { CSS_HELPERS, MEDIA_SCREENS, THEME_COLORS } from '@theme/constants'
import { useRouter } from 'next/navigation'
import { CgTrees } from 'react-icons/cg'
import styled from 'styled-components'

const BoxUi = ({
  children,
  center,
  background,
  bg,
  transparent,
  hide,
  lander,
  justifyAll,
  noppadingX,
  noppadingY,
  // overflow = 'hidden',
}) => {


  const { asPath } = useRouter();
  const isLogin = asPath?.includes('/auth/login');

  if (hide) return <></>
  if (lander) return (<Lander>
    <div className="lander_item">
      <CgTrees size={'6em'} />
      <p className='strong600 uppercase'>¿Que esperas para proyectar tus sueños?</p>
    </div>
  </Lander>);





  return (
    // <div data-aos={!background ? 'zoom-in': 'zoom-out'}>
    <View
      style={{
        // overflow,
      }}
      isLogin={isLogin}
      background={background}
      transparent={transparent}
      justifyAll={justifyAll}
      noppadingX={noppadingX}
      noppadingY={noppadingY}
      center={center}
      bg={bg}
      className="box_ui"
    >
      <div className="contentView" >
        {children}
      </div>
    </View>
    // </div>

  )
}

export default BoxUi;

const View = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // overflow: hidden;

    ${props => props.justifyAll && `height: 100vh; padding-top:12em;`}
    ${props => props.background ? `background:${THEME_COLORS.light.bg.alt};` : `background:${THEME_COLORS.light.bg.main};`};
    ${props => props.transparent && `background:#00000000;`}
    ${props => props.bg && `background:${props.bg};`}

    .contentView {   
        @media screen and  (min-width: ${MEDIA_SCREENS.LG}px)  { 
          max-width: 1280px;  
          width: 100%;
          padding:2em  3em;
          ${props => props.noppadingX && `padding:2em  0;`}
          ${props => props.noppadingY && `padding:0  3em;`}
        }
        @media screen and  (min-width: ${MEDIA_SCREENS.MD}px) and (max-width: ${MEDIA_SCREENS.LG}px) { 
          max-width: 1280px;  
          width: ${props => props.isLogin ? 'auto' : '100%'};
          padding:2em  3em;
          /* background-color: green; */
          ${props => props.noppadingX && `padding:2em  0;`}
          ${props => props.noppadingY && `padding:0  3em;`}
        };
        @media screen and  (min-width: ${MEDIA_SCREENS.SM}px) and (max-width: ${MEDIA_SCREENS.MD}px) { 
          width: 100%;
          padding:2em  2em;
          /* background-color: blue; */
          ${props => props.noppadingX && `padding:2em  0;`}
          ${props => props.noppadingY && `padding:0  3em;`}
        }
        @media screen and  (min-width: ${MEDIA_SCREENS.XS}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
          width: 100%;
          padding:2em  2em;
          /* background-color: yellow; */
        }
        @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.XS}px) { 
          width: 100%;
          padding:2em 1em;
        }
       
        
        ${props => props.noppadingX && `padding-bottom:0 !important; padding-top:0 !important;`};
        
        ${props => props.center && `
              display:flex;
              flex-direction:column ;
              justify-content:center;
              align-items:center ;
        `}


    };
`

const Lander = styled.div`
  display: flex ;
  justify-content:center ;
  align-items:center ;
  height:100% ;
  .lander_item {
      background-color:${THEME_COLORS.light.primary.main} ;
      ${CSS_HELPERS.BOX_SHADOW}
      width:30em;
      height:30em ;
      padding: 1em;
      display: flex ;
      justify-content:center ;
      align-items: center ;
      border-radius:${CSS_HELPERS.RADIUS} ;
      flex-direction: column ;
      gap:1em;
      p{
        color:${THEME_COLORS.light.primary.contrastText};
        text-align:center ;
      }
      svg{
        color:${THEME_COLORS.light.primary.contrastText};
      }
  }

`

