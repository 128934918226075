import React from "react";
import { FaSpinner } from "react-icons/fa";
import { ImSpinner4 } from "react-icons/im";
import styled from "styled-components";

const Spinner = () => {
  return (
    <ViewSpinner>
      <ImSpinner4 className="spinning" size={30} />
    </ViewSpinner>
  );
};

const ViewSpinner = styled.div`
/* 
.spinning {
    animation: spinner 1s linear infinite;
    color: #989898;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
} */
`
export default Spinner;
